import React from 'react'
import './PrivacyPolicy.css'
import Footer from '../components/Footer'

const PrivacyPolicy = () => {
  return (
    <>
      <div className='privacy-policy-head'>
        <p className='heading-privacy-policy'>Privacy Policy</p>
      </div>
      <div className='privacy-policy-main'>
        <p className='privacy-content-date'>Last updated: 29 June, 2021</p>
        <p className='first-para'>
          Welcome to ChefConnectIndia’s privacy policy (“Privacy Policy” or “Policy”).
          <br></br>
          ChefConnectIndia Hospitality Private Limited and its affiliates (collectively, “ChefConnectIndia”, “we” or “us”) are engaged in the business of providing web-based solutions to facilitate connections between customers that seek specific services and independent service providers / professionals that offer these services. This Policy outlines our practices in relation to the collection, storage, usage, processing, and disclosure of personal data that you have consented to share with us when you access, use, or otherwise interact with our website available at <a href="https://www.chefconnectindia.com/"> https://www.chefconnectindia.com/</a>. In this Policy, the services offered by service professionals/chefs on or through the Platform are referred to as “Chef Services”. For more information about the terms of use of how you can avail the Services of ChefConnectIndia and/or provide/avail the Chef Services, please refer to the respective Terms of use at User T&C and Chef T&C.<br></br>
          At ChefConnectIndia, we are committed to protecting your personal data and respecting your privacy. In order to provide you with access to the Services and the Chef Services, we have to collect and otherwise process certain data about you. This Policy explains how we process and use personal data about you.
          <br></br>
          Please note that unless specifically defined in this Policy, capitalised terms shall have the same meaning ascribed to them in our respective user/chef Terms and Conditions, available at User T&C and Chef T&C (collectively and/or individually “Terms”).
Please read this Policy in consonance with the Terms. By using the Services, you confirm that you have read and agree to be bound by this Policy and consent to the processing activities described under this Policy. Please refer to Section 1 to understand how the terms of this Policy apply to you.<br></br>
<br></br>
1. OBJECTIVE AND BACKGROUND<br></br>
The purpose of this Privacy Policy is to maintain the privacy of and protect the personal information of employees, associates, customers and business partners of ChefConnectIndia and ensure compliance with laws and regulations applicable to ChefConnectIndia. This Privacy Policy sets out how ChefConnectIndia uses and protects any information that you have consented to share with ChefConnectIndia when you use this website. ChefConnectIndia is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this Privacy Policy and prior consent. ChefConnectIndia may change this Policy from time to time by updating this page and use of our services after an update constitutes consent to the updated policy to the extent permitted by law.<br></br>
<br></br>
2. SCOPE<br></br>
This Policy is applicable to all ChefConnectIndia employees, associates, customers and business partners who may receive personal information, have access to personal information collected or processed, or who provide information to ChefConnectIndia.<br></br>
“PERSONAL DATA”  means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).<br></br>
“USAGE DATA” is data collected automatically either generated by the use of Service or from Service infrastructure itself (for example, the duration of a page visit).
“USER DATA” includes both, Personal Data, and Usage Data, And any other data obtained from the User including via Cookies.<br></br>
“COOKIES” are small files stored on your device (computer or mobile device)<br></br><br></br>
3. WHAT WE COLLECT<br></br>
We may collect Personal Data, Usage Data, including your personal information, and your sensitive personal data and information (as defined in the Information Technology (Reasonable Security Practices and Procedures, and sensitive personal data or information) Rules, 2011, and specifically including:<br></br>
<ul className='privacy-list'>
          <li>Contact Data, such as your mailing or home address, location, email addresses, and mobile numbers.</li>
          <li>All information that a user chooses to share on the Platform such as photos, screenshots, comments, events, or other user-generated content that may be uploaded on our Website.</li>
          <li>Other information such as postal code, password, birthdate, height, weight, your gender, education qualifications and proof thereof, mobile number, address, and other information you may provide in order to validate your account/use the Services. Your profile picture, if any, along with basic information such as your name/company name may be viewed by other users on the platform as part of your account information.</li>
          <li>Contacts Information and lists, stored in / used in your phone and/or on your sim card.</li>
          <li>Identity and Profile Data, such as your name, username or similar identifiers, photographs, likenesses, your image / photograph as may be uploaded/provided to us separately, and gender.</li>
          <li>Marketing and Communications Data, such as your address, email address, information posted in service requests, offers, wants, feedback, common pictures and discussions in our blog and chat boxes, responses to user surveys and polls, your preferences in receiving marketing communications from us and our third parties, and your communication preferences. We also collect your chat and call records when you communicate with service professionals through the Platform.</li>
          <li>Access to your messages/ SMS data.</li>
          <li>Photos, storage, files, etc. that you may access/upload during your use of the Services and the Platform, including for grievances, customer support, reviews, account creation, etc.</li>
          <li>Device information: We may collect information about the device you are using the Services on, including what type of device it is, what operating system you are using, device settings, application IDs, unique device identifiers, and crash data. Whether we collect some or all of this information often depends on what type of device you are using and its settings.</li>
          <li>Technical data and log data, which includes your IP address, browser type, internet service provider, details of the operating system, access time, page views, device type, frequency of visiting our website and use of the Platform, website and mobile application activity, clicks, date and time stamps, location data, and other technology on the devices that you use to access the Platform. This log data may include your internet protocol address, the address of the web page you visited before using the Services, your browser type and settings, the date and time of your use of the Services, information about your browser configuration and plug-ins, language preferences, and cookie data, locale and language preferences, and system configuration information.</li>
          <li>Transaction Data, such as details of the Services or Chef Services you have availed/provided, a limited portion of your credit or debit card details for tracking transactions that are provided to us by payment processors, and UPI IDs for processing payments.</li>
          <li>Data about your food and beverage preferences and consumptions, including specifically stocks of food at home, kinds of brands/food preferred/consumed/ordered, etc.</li>
          <li>Usage Data, which includes information about how you use the Services and Chef Services.</li>
          <li>We also collect, use, and share aggregated data such as statistical or demographic data for any purpose. Aggregated data could be derived from your personal data but is not considered personal data under law as it does not directly or indirectly reveal your identity. However, if we combine or connect aggregated data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this Policy.</li>
          <li>Government issued identifiers such as Aadhar details, PAN Card (if provided to us by you), driver’s license, or other forms of governmental identification.</li>
          <li>Other information relevant to customer surveys and/or offers/and/or required to use/avail the Services and/or Chef Services.</li>
          <li>Geo-location information: Precise GPS location from devices may be required for effective delivery of the Services, and will as such be collected with your permission. Wi-Fi and IP addresses received from your browser or device may be used to determine approximate location.</li>
          <li>If, when using the Services, you integrate with a third-party service, we will connect that service to ours. The third-party provider of the integration may share certain information about your account with us, including certain information about you, including but not limited to your contacts and other "likes". You would also be bound by the privacy policies of these third-party services. However, we do not receive or store your passwords for any of these third-party services.</li>
          <li>Third Party Data: We may also receive information from affiliates in our corporate group, our partners, or others that we use to make our own information better or more useful. This might be aggregate level information, such as which IP addresses go with which PIN/ZIP codes, or it might be more specific information, such as about how well an online marketing or email campaign performed.</li>
        </ul>
        4. HOW DO WE COLLECT PERSONAL DATA?<br></br>
We use different methods to collect personal data from and about you including through:
<ul>
<li>Direct Interactions. You provide us your personal data when you interact with us. This includes personal data you provide when you:</li>
      <li>Create an account or profile with us;</li>
      <li>Use our Services or carry out other activities in connection with the Services;</li>
      <li>Enter a promotion, user poll, or online surveys;</li>
      <li>Request marketing communications to be sent to you; or</li>
      <li>Report a problem with the Platform and/or our Services, give us feedback or contact us.</li>
      <li>Automated technologies or interactions. Each time you visit the Platform or use the Services, we will automatically collect Technical Data about your equipment, browsing actions, and patterns. We collect this personal data by using cookies, web beacons, pixel tags, server logs, and other similar technologies. We may also receive Technical Data about you if you visit other websites or apps that employ our cookies.
  </li>
  <li>Third parties or publicly available sources. We will receive personal data about you from various third parties:</li>
      <li>Technical data from analytics providers such as Facebook and advertising networks;</li>
      <li>Identity and profile-related Data and Contact Data from service professionals, publicly available sources, etc.;</li>
      <li>Personal data about you from our affiliate entities.</li>
  <li>
    We may also use 3rd-party browser and mobile analytics services including but not limited to Google Analytics, Hotjar, GetSiteControl, Mixpanel, Leadsquared, Wistia, YouTube and Drift on the Services. These services use Data Collection Tools to help us analyze your use of the Services, including information like the third-party website you arrive from, how often you visit, events within the Services, usage and performance data. We use this data to improve the Services, better understand how the Services perform on different devices and provide information that may be of interest to you. We may also obtain both personal and non-personal information about you from advertising companies, and ads network, business partners, contractors and other third parties and add it to our account information or other information we have collected. Examples of such information that we may receive include: updated delivery and address information, purchase history, and additional demographic information. We may combine this information with information we collect through our Platform or from other sources.
  </li>
  <li>
    You may optionally provide us with this information through third-party sign-in services such as but not limited to Facebook, LinkedIn, and Google. In such cases, we procure and store any information about you that is made available to us through these sign-in services.
  </li>
  <li>
    We collect user data including personal information and sensitive personal information to operate effectively and provide you with the best experiences with our services. You provide some of this information directly, such as when you create an account, make a purchase, or contact us for support. We get some of your information, such as your IP address, by recording how you interact with our services. We may also get information about you from our business partners or other third parties.
  </li>
  <li>
    We may receive and collect certain personal information automatically, as outlined elsewhere in this Policy, and including analytics regarding our Websites and Apps, information your Internet browser automatically sends when you visit our Websites and Apps, and information collected by cookies. We may collect personal information that can identify you such as your name and email address, and other information that does not identify you.
  </li>
</ul>
5. WHAT WE DO WITH THE INFORMATION WE GATHER<br></br>
We will only use your personal data when the law allows us to. Most commonly, we will use your personal data where we need to provide you with the Services, enable you to use the Chef Services, or where we need to comply with a legal obligation. We use your personal data for the following purposes: We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:<br></br>
<ul>
    <li>To verify your identity to register you as a user, and create your user account with us on the Platform;</li>
    <li>To provide the Services to you;</li>
    <li>To enable the provision of Chef Services to you;</li>
  <li>To monitor trends, and/or to personalize your experience;</li>
  <li>To improve the functionality of our Services based on the information and feedback we receive from you;</li>
  <li>To improve customer service to more effectively respond to your Service requests, support needs, and/or your orders of Chef Services;</li>
  <li>To track transactions and process payments;</li>
  <li>To improve our products and services, business and delivery models;</li>
  <li>To periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided, and/or to provide you offers / invites / coupons of the Services, Chef Services and/or third party products and services;</li>
  <li>To use your information to contact you for market research purposes. We may contact you by email, phone, fax, in person, or mail. We may use the information to customize the website according to your interests;</li>
  <li>To assist with the facilitation of the Chef Services offered to you, including to send you information and updates about the Chef Services you have availed;</li>
  <li>To market and advertise the Services to you;</li>
  <li>To manage our business and in any other way we may describe when you provide the information;</li>
  <li>To use the information you provided to occasionally notify you about the product or services and other valuable updates to personalize your shopping transactions (if you prefer that we not contact you in this manner please follow the Opt-Out instructions elsewhere in this Policy);</li>
  <li>To learn more about you by linking your data with additional data received through third-party data providers or analyzing the data with the help of analytics service providers;</li>
  <li>To identify unique users across devices;</li>
  <li>To analyze trends and traffic, track purchases, and track usage data;</li>
  <li>To comply with our legitimate business requirements, and/or legal obligations;</li>
  <li>To administer and/or to protect our business and the Services, including for troubleshooting, data analysis, system testing, and performing internal operations;</li>
  <li>To tailor advertisements across devices;</li>
  <li>To perform our obligations that arise out of the arrangement we are about to enter, or have entered with you;</li>
  <li>To enforce our Terms; and</li>
  <li>To respond to court orders, establish or exercise our legal rights, or defend ourselves against legal claims.</li>
  <li>To verify your eligibility and deliver prizes in connection with promotions, contests and sweepstakes;</li>
  <li>For any purpose, we in our sole discretion, otherwise determine to be necessary to ensure the safety or integrity of our users, employees, third parties, the public, or our Services.</li>
  <li>You agree and acknowledge that by using our Services and creating an account with us on the Platform, you authorize us, our service professionals, associate partners, and affiliates to contact you via email, phone or otherwise. This is to provide the Services to you and to ensure that you are aware of all the features of the Services, and for related purposes.</li>
  <li>You agree and acknowledge that any and all information pertaining to you, whether or not you directly provide it to us (via the Services or otherwise) including but not limited to personal correspondence such as emails, instructions from you, etc., may be collected, compiled, and shared by us in order to render the Services to you. This may include but not be limited to service professionals who provide or seek to provide you with Chef Services, vendors, social media companies, third-party service providers, storage providers, data analytics providers, consultants, lawyers, and auditors. We may also share this information with other entities in connection with the above-mentioned purposes.</li>
  <li>You agree and acknowledge that we may share data without your consent, when it is required by law or by any court or government agency or authority to disclose such information. Such disclosures are made in good faith and belief that it is reasonably necessary to do so for enforcing this Policy and the Terms, or in order to comply with any applicable laws and regulations.</li>
  <li>We may share/use/process your personal data other than as described in this policy for other business purposes as we may require.</li>
  <li>We may use your personal data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or by emailing at <a href="mailto:info@thechefconnect.co.in">info@thechefconnect.co.in</a></li>
  <li>You are free to refuse to provide your personal data/information as referred above. However, in this case, you may not be able to access/use the Platform (in whole or part), and we reserve the right to not provide you the Services, and/or refuse to facilitate the provision of the Chef Services by/to you.</li>
</ul>
6. COOKIES <br></br>
<ul>
    <li>Cookies are alphanumeric identifiers or small files that we transfer to your device through your web browser/mobile device to enable our systems to recognise your browser and to provide features such as ‘Recommended for You’, personalised advertisements on other websites. Cookies can be either session cookies or persistent cookies. A session cookie expires when you close your browser and is used to make it easier for you to navigate our website. A persistent cookie remains on your hard drive for an extended period. Cookies help us improve our website and deliver a better and personalized service. We and other service providers we use, (such as Google Analytics, third party advertisers, etc) may use server log files and automated data collection tools like cookies, tags, scripts, customized links, device or browser fingerprints, and web beacons (“Data Collection Tools”) when you interact with/use/access the Services. These Data Collection Tools may automatically track and collect certain information including System Data and Usage Data (as described above) when you use the Services. In some cases, we couple the data gathered through these Data Collection Tools to other data that we may collect. Most browsers allow you to prevent them from accepting new cookies or disable cookies altogether. Each browser is different; so, check the ‘help’ menu of your browser to learn how to change your cookie preferences. Since cookies allow you to take advantage of some of the website’s essential features, we recommend that you leave them turned on. If you choose to decline cookies, you may not be able to use other interactive features on our Website. However, since there is no universal industry standard for a “do not track” signal, we do not currently obey any such signals/messages from your browsers.</li>
    <li>To estimate our users’ size and usage pattern.</li>
  <li>To store information about your preferences, and allow us to customize our website according to your interests.</li>
  <li>To speed up your searches.</li>
  <li>To help us distinguish you from other users of the Platform, understand and save your preferences for future visits, keep track of advertisements and compile aggregate data about site traffic and site interaction so that we can offer you a seamless user experience. We may contact third-party service providers to assist us in better understanding our site/app visitors/users.</li>
  <li>To recognize you when you return to our website.</li>
  <li>Provide general internal analytics;</li>
  <li>Study traffic patterns in order to improve Website performance, to customize your experience, and to better match your interests and preferences;</li>
  <li>Support security measures, such as requiring re-login into the website after a certain time period has elapsed;</li>
  <li>Save your username and password, if you login to our Website, so that we can process the saved information and quickly log you into the Website; and</li>
  <li>Assist in identifying possible fraudulent activities.</li>
  <li>We also allow third party advertising and analytics cookies. These are placed by or on behalf of independent advertisers who are advertising on our site. These cookies may be placed within the advertisement and elsewhere on our site. They are anonymous – they cannot identify individuals. Such cookies are used for statistical analysis by allowing the advertiser to count how many people have seen their advertisement, or have seen it more than once. They might also allow the advertiser to tailor advertising to you when you visit other websites. We also use web beacons (which small objects that allow us to analyse the actions of visitors and Users using the Services) for various purposes including identifying whether a page was visited, a message was opened, advertising more efficiently by excluding/including specific Users/visitors from certain promotional messages, or identifying the source of a new page visit.</li>
  <li>We use the following types of cookies among others:</li>
  <li>Preferences: Cookies which remember information about the browser used, and preferred settings that affect/modify/alter the appearance and behavior of the Services.</li>
  <li>Security: Cookies which enable you to log in and access the Services/Platform; protect from fraudulent logins; and help detect and prevent abuse or unauthorized use of your account.</li>
  <li>Functional: Cookies that store functional settings (like the volume level you set for video playback).</li>
  <li>Advertising: Cookies which enable us to serve you advertisements which may be relevant to you and your interests.</li>
  <li>Session State: Cookies that track your interactions with the Services to help us improve the Services and your experience, remember details such as login details, and enable processing of your purchases. These are necessary for the Services to work properly, and if you disable them partially or wholly, then certain functionalities will break or be unavailable.
    <br></br>
    Some third-party partners who provide certain features on our site may also use Local Storage Objects  to collect and store data.We have no access to third party cookies and third-party organisations do not have access to ours. The third-party organisations that place cookies have their own privacy policies.
  </li>
  <li>Additionally, you may encounter cookies or other similar devices on certain pages of the Platform that are placed by third parties. You acknowledge that we do notcontrol the use of cookies by third parties. If you send us personal correspondence, such as emails, or if other users or third parties send us correspondence about your activities, or postings on the Platform, we may collect such information within a file specific to you. You may refuse to accept Cookies by activating the setting on your browser which allows you to refuse the setting of Cookies. However, if you select this setting you may be unable to access certain parts of our website. Unless you have adjusted your browser setting so that it will refuse Cookies, our system may issue Cookies when you log on to the website. The use of Cookies by our partners, affiliates, advertisers or service providers is not covered by the policy. We may share your personal data with third parties as set out in this policy, including with users, chefs (as the case may be respectively), internal third parties, external third parties such as analytics service providers, registered users of our Platform, regulators and other bodies, as required by law or regulation, etc.</li>
</ul>
7. SECURITY <br></br>
We implement appropriate security measures and privacy-protective features on our Platform including encryption, password protection, and other security measures to protect your personal data from unauthorised access and disclosure, and follow standards prescribed by applicable laws. We are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.<br></br>Where you have chosen a password that enables you to access certain parts of the Services and/or Chef Services, you are responsible for keeping password secret and confidential. We will not be responsible for any unauthorised use of your information, or for any lost, stolen, or compromised passwords, or for any activity on your user account due to such unauthorised disclosure of your password. In the event your password has been compromised in any manner whatsoever, you should promptly notify us to enable us to initiate a change of password. While we are focused on the security of your personal information and follow strict standards, processes and procedures that are designed to protect your personal information, you must remember that the Internet is a global communications vehicle open to threats, viruses and intrusions from others and so we cannot promise, and you should not expect, that we will be able to protect your personal information at all times and in all circumstances.<br></br>
The security of your data is important to us but remember that no method of transmission over the internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal data and information, we cannot guarantee its absolute security.<br></br><br></br>

8. YOUR RIGHTS
<ul>
    <li>Inaccurate User Information
    You have the right to update or correct your information provided under Clause 3. When you use our Services, we will on a best efforts basis, try to provide you with the ability to access and correct inaccurate or deficient data, subject to legal requirements.</li>
    <li>Opting-out of Marketing and Promotional Communications
    When we send you marketing and promotional content through email, we make best efforts to provide you with the ability to opt-out of such communications by using the opt-out instructions provided in such emails. You understand and acknowledge that it may take us up to 7 (seven) working days to give effect to your opt-out request. Please note that we may still send you emails about your user account or any services you have requested or received from us.</li>
</ul>
9. OBLIGATION<br></br>
You agree that you will not host, display, upload, modify, publish, transmit, store, update or share any information that-<br></br>
- belongs to another person and to which you do not have any right;
<br></br>
- is defamatory, obscene, pornographic, paedophilic, invasive of another’s privacy, including bodily privacy, insulting or harassing on the basis of gender, libellous, racially or ethnically objectionable, relating or encouraging money laundering or gambling, or otherwise inconsistent with or contrary to the laws in force;
<br></br>
- is harmful to child;
<br></br>
- infringes any patent, trademark, copyright or other proprietary rights;
<br></br>
- violates any law for the time being in force;
<br></br>
- deceives or misleads the addressee about the origin of the message or knowingly and intentionally communicates any information which is patently false or misleading in nature but may reasonably be perceived as a fact;
<br></br>
- impersonates another person;
<br></br>
- threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign States, or public order, or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or is insulting other nation;
<br></br>
- contains software virus or any other computer code, file or program designed to interrupt, destroy or limit the functionality of any computer resource;
<br></br>
-is patently false and untrue, and is written or published in any form, with the intent to mislead or harass a person, entity or agency for financial gain or to cause any injury to any person.
<br></br>
ChefConnectIndia shall have the right to terminate your access or usage rights if you do  not comply with rules and regulations, Privacy Policy or user agreement for access or usage of the computer resource of such intermediary or if the information shared does not comply with the above obligation.<br></br><br></br>
10. TRANSFERS AND DISCLOSURES OF YOUR PERSONAL DATA
<br></br>Your information, including Personal Data, may be transferred to – and maintained on – computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction. We share your personal information as necessary to provide the services you request, including sharing information with third party service providers; when required by law; to protect rights and safety, and with your consent.<br></br>We may share personal information with:
<ul>
<li>Authorized service providers: We may share your personal information with our authorized service providers that perform certain services on our behalf. These services may include fulfilling orders, processing credit card payments, delivering packages, providing customer service and marketing assistance, performing business and sales analysis, data analysis, supporting our Platform functionality, and supporting contests, promotions, sweepstakes, surveys and other features offered through our Platform. These service providers may have access to personal information needed to perform their functions but are not permitted to share or use such information for any other purposes.</li>
  <li>Business partners: When you make purchases or engage in promotions offered through our Platform, we may share personal information with the businesses with which we partner to offer you those products, services, promotions, contests and/or sweepstakes. When you elect to engage in a particular merchant's offer or program, you authorize us to provide your email address and other information to that merchant.</li>
  <li>Third parties’ advertisers on our Platform: We may allow third-parties, advertising companies, and ad networks, to display advertisements on our Websites and Apps. These companies also may use tracking technologies, such as cookies, to collect information about users who view or interact with their advertisements. They may collect information about where you, or others who are using your computer, saw and/or clicked on the advertisements they deliver, and possibly associate this information with your subsequent visits to the advertised websites. They also may combine this information with other personal information they collect from you or from other third parties.</li>
  <li>With Analytics and Data Enrichment Services: As part of our use of third-party analytics tools like Google Analytics and data enrichment services like Clearbit or Pipl, we share certain data, or de-identified data as needed.</li>
  <li>To Power Social Media Features: The social media features in the Services (like the Facebook Share) may allow the third-party social media provider to collect information including your IP address, which page of the Services you’re visiting, etc., and to set a cookie to enable the feature. Your interactions with these features are governed by the third-party company’s privacy policy.</li>
  <li>For Advertising: We may use and share certain data with third-party advertisers and networks to show general demographic and preference information among our users. We may also allow advertisers to collect Data through Data Collection Tools, and to use this data to offer you targeted ad delivery to personalize your user experience (through behavioral advertising) and also to undertake web analytics.</li>
  <li>For Security and Legal Compliance: We may disclose your data to third parties if we (in our sole discretion) believe in good faith, that the disclosure is:</li>
      <li>Permitted or required by law; including any order or proceeding</li>
      <li>Required to enforce our Terms of Use, Privacy Policy, and other legal agreements;</li>
      <li>Required to detect, prevent, or address fraud, abuse, misuse, potential violations of law (or rule or regulation), or security or technical issues; or</li>
      <li>Required in our view to protect against imminent harm to the rights, property, or safety of ChefConnectIndia, Users, Chefs, employees, members of the public, and/or our Services.</li>
  <li>To our auditors and legal advisors in order to assess our disclosure obligations and rights under this Privacy Policy.</li>
  <li>During a Change in Control: If ChefConnectIndia undergoes a business transaction like a merger, acquisition, corporate divestiture, or dissolution (including bankruptcy), etc, we may share, disclose, or transfer all of your data to the successor organization during such transition or in contemplation of a transition (including during due diligence).</li>
  <li>After Aggregation/De-identification: we can disclose or use aggregate or de-identified data for any purpose.</li>
  <li>With Your Permission: with your consent, we may share data to third parties outside the scope of this Privacy Policy.</li>
  <li>Other situations: We also may disclose your information:</li>
      <li>In response to a subpoena or similar investigative demand, a court order, or a request for cooperation from a law enforcement or other government agency; to establish or exercise our legal rights; to defend against legal claims; or as otherwise required by law. In such cases, we may raise or waive any legal objection or right available to us.</li>
      <li>When we believe disclosure is appropriate in connection with efforts to investigate, prevent, or take other action regarding illegal activity, suspected fraud or other wrongdoing; to protect and defend the rights, property or safety of our company, our users, our employees, or others; to comply with applicable law or cooperate with law enforcement; or to enforce our website terms and conditions or other agreements or policies.</li>
      <li>In connection with a substantial corporate transaction, such as the sale of our business, a divestiture, merger, consolidation, or asset sale, or in the unlikely event of bankruptcy.</li>
      <li>When you provide us with your consent to share your information with third parties.</li>
      <li>To any service providers which we may employ third party companies and individuals to facilitate our Services provide Service on our behalf, perform Service-related services or assist us in analysing how our Service is used.</li>
  <li>Third-Party Privacy Policies: Any third parties to whom we may disclose personal information may have their own privacy policies which describe how they use and disclose personal information. Those policies will govern use, handling and disclosure of your personal information once we have shared it with those third parties as described in this Policy. If you want to learn more about their privacy practices, we encourage you to visit the websites of those third parties.</li>
  <li>Aggregated and Non-personal Information: We may share aggregated and non-personal information we collect under any of the circumstances set forth in this Policy. We may also share it with third parties to develop and deliver targeted advertising on our Websites and Apps and on websites and apps of third parties. We may combine non-personal information we collect with additional non-personal information collected from other sources. We also may share aggregated information with third parties, including advisors, advertisers and investors, for the purpose of conducting general business analysis. For example, we may tell our advertisers the number of visitors to our Websites and Apps and the most popular features or services accessed. This information does not contain any personal information and may be used to develop website content and services that we hope you and other users will find of interest and to target content and advertising.</li>
</ul>
11. DELETION OF ACCOUNT<br></br>
Notwithstanding anything contained in the policy, you may delete your account as well as your personal data stored with ChefConnectIndia by sending an email to <a href="mailto:info@thechefconnect.co.in">info@thechefconnect.co.in</a> We may take up to 30 (Thirty) working days to process your request. Once your account is deleted, you will lose access to all services. For avoidance of doubt, it is hereby clarified that all data with respect to transactions performed by you on the platform will be retained in accordance with applicable law.<br></br><br></br>
12. LINKS TO OTHER WEBSITES<br></br>Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this Privacy Policy. You should exercise caution and look at the privacy statement applicable to the website in question.<br></br>We will not sell, distribute or lease your personal information to third parties unless we have your prior permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen. You may request details of personal information which we hold about you for a small fee. If you would like a copy of the information about you, please write to <a href="mailto:info@thechefconnect.co.in">info@thechefconnect.co.in</a><br></br>If you believe that any information, we are holding on you is incorrect or incomplete, please write to or email us as soon as possible, at the above address. We will promptly correct any information found to be incorrect.<br></br><br></br>
13.  DATA RETENTION AND STORAGE<br></br>
<ul>
    <li>You agree and acknowledge that your personal data will continue to be stored and retained by us for as long as necessary to fulfil our stated purpose(s) and for a reasonable period after the termination of your account on the platform or access to the services to comply with our legal rights and obligations.</li>
    <li>In some circumstances, we may aggregate your personal data (so that it can no longer be associated with you) for research or statistical purposes, in which case we may use this information indefinitely without further notice to you.</li>
    <li>ChefConnectIndia will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy. Your information collected by us may be stored by us or our affiliates, business partners, suppliers and subcontractors. Information may also be stored in or outside India. Your information shall be stored only for the period necessary for purposes specified hereinabove.</li>
    <li>By visiting, accessing and/or using the Website and/or submitting your information, you consent to such storage of your information, including your Personal Information and Usage Data.</li>
</ul>
14. DISPUTE RESOLUTION FOR CUSTOMER / THIRD PARTY<br></br>Customers / Third Party with inquiries or complaints about the processing of their personal information shall bring the matter to the attention of the Grievance Officer in writing. Any disputes concerning the processing of the personal information of non-employees shall be resolved through alternative dispute resolution mechanisms such as mediation, arbitration, conciliation etc.<br></br><br></br>
15. CONTACTING US<br></br>If there are any questions regarding this Policy you may contact us using the information on the ‘Contact Us’ page on ChefConnectIndia’s website, or reach out to our Grievance Officer.
  </p>
      </div>
      <Footer />
    </>
  )
}

export default PrivacyPolicy
