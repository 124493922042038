import React from 'react';
import './NewHero.css';

const NewHero = () => {
    return (
        <div className="background-container">
            <div className="text-overlay">
                <h1>Cooking with love provides food for the soul.</h1>
            </div>
        </div>
    );
};

export default NewHero;
