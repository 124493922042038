const cityname = [
  {
    tag: "Jaipur",
    chefs: [
      { name: "Vijay Sharma", },
      { name: "Mohammed Istekhar" },
      { name: "Shital Nama" },
      { name: "Praveen Singh Negi" },
      { name: "Bharat Kumar" },
    ],
  },
  {
    tag: "Mumbai",
    chefs: [
      { name: "Tek Saud" },
      { name: "Fariyad Ali" },
      { name: "Sumit Paswan" },
      { name: "Saheb Mallick" },
      { name: "Dharmik Pandya" },
    ],
  },
  {
    tag: "Chennai",
    chefs: [
      { name: "Amit Sarkar" },
      { name: "Manikandan" },
      { name: "Padma" },
      { name: "Monika" },
      { name: "Jitendra Mahto" },
    ],
  },
  {
    tag: "Delhi",
    chefs: [
      { name: "Mahendra singh" },
      { name: "Sudhir kumar" },
      { name: "Divyansh Mishra" },
      { name: "Gajendra Singh Pundi" },
      { name: "Mahendra Singh" },
    ],
  },
  {
    tag: "Bangalore",
    chefs: [
      { name: "Sudeep Darnal" },
      { name: "Pratik Jadhav" },
      { name: "Sreekanth Radhakrishnan" },
      { name: "Ankit Singh" },
      { name: "Kingmari" },
    ],
  },
  {
    tag: "Hyderabad",
    chefs: [
      { name: "Buyya Vamshi" },
      { name: "Manikanta" },
      { name: "John Reddy" },
      { name: "Chetan" },
      { name: "Saivamshi Challa" },
    ],
  },
  {
    tag: "Pune",
    chefs: [
      { name: "Prashant Kamble" },
      { name: "Shubham Dattatray Padekar" },
      { name: "Umesh Pawar" },
      { name: "Rajesh Choure" },
      { name: "Aishwarya Kulkarni" },
    ],
  },
  {
    tag: "Kolkata",
    chefs: [
      { name: "Sayan Rakshit" },
      { name: "Rajkumar Ghosh" },
      { name: "Anindita Sharma" },
      { name: "Chandan Shaw" },
      { name: "Anil Kumar Sahu" },
    ],
  },
];

export default cityname;